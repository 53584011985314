@import '../theme-bootstrap';

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
}

[hidden] {
  display: none;
}

html {
  font-size: 100%;
  overflow-y: scroll;
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  &.search-overlay-displayed {
    overflow: hidden;
    @include breakpoint($medium-up) {
      overflow: auto;
    }
  }
}

body {
  margin: 0;
  &.search-overlay-displayed {
    overflow: hidden;
    position: fixed;
    @include breakpoint($large-up) {
      position: static;
    }
  }
}

a:focus {
  outline: thin dotted;
}

a:hover,
a:active {
  text-decoration: underline;
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

blockquote {
  margin: 1em 40px;
}

blockquote:first-child {
  margin-top: 0;
}

dfn {
  font-style: italic;
}

mark {
  background: $color-white;
  color: $color-black;
}

pre,
code,
kbd,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}

pre,
.codeblock {
  padding: 1em;
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

q {
  quotes: none;
}

q:before,
q:after {
  content: '';
  content: none;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

ul,
ol {
  list-style: none;
  margin: 0;
  padding: 0;
}

dl {
  margin: 1em 0;
}

dl:first-child {
  margin-top: 0;
}

dl:only-child {
  margin: 0;
}

dt {
  font-weight: bold;
}

dd {
  margin: 0 0 0 1em;
}

nav ul,
nav ol {
  list-style: none;
  list-style-image: none;
}

img {
  border: 0;
  -ms-interpolation-mode: bicubic;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td,
th {
  vertical-align: top;
  text-align: left;
}

form {
  margin: 0;
}

fieldset {
  border: 1px solid $color-primary-200;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
}

button,
input,
select,
textarea {
  font-size: 100%;
  font-family: sans-serif;
  margin: 0;
  padding: 2px;
  vertical-align: baseline;
}

button,
input {
  line-height: normal;
}

button,
input[type='button'],
input[type='reset'],
input[type='submit'] {
  cursor: pointer;
}

html button,
html input[type='button'],
html input[type='reset'],
html input[type='submit'] {
  -webkit-appearance: button;
}

input[type='checkbox'],
input[type='radio'] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}

input[type='search'] {
  -webkit-appearance: textfield;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

input[type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

input[disabled],
select[disabled],
textarea[disabled],
button[disabled],
input[disabled] + label {
  cursor: not-allowed;
}

body {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.invisible,
.element-invisible {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
}

.invisible.focusable:active,
.invisible.focusable:focus,
.element-invisible.element-focusable:active,
.element-invisible.element-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static !important;
  width: auto;
}

.messages {
  background-color: #bedb82;
  color: $color-black;
  line-height: 1.6;
  border-bottom: solid 1px rgba(137, 179, 51, 0.8);
  padding: 20px;
}

.messages:after {
  content: '';
  display: table;
  clear: both;
}

.messages + .messages {
  border-top: none;
}

.messages a {
  border-bottom: dotted 1px;
  padding-bottom: 2px;
  text-decoration: none;
}

.messages a:link,
.messages a:visited {
  color: $color-black;
}

.messages.error {
  background-color: #f14a29;
  color: $color-white;
}

.messages.error h2 {
  background-color: rgba(201, 38, 13, 0.8);
}

.messages.error a:link,
.messages.error a:visited {
  color: $color-white;
}

.messages.warning {
  background-color: #ffe375;
  border-color: rgba(236, 188, 0, 0.8);
  color: #111;
}

.messages.warning h2 {
  background-color: rgba(236, 188, 0, 0.8);
}
