/* Primary grey color types */
$color-primary-900: #212121;
$color-primary-800: #424242;
$color-primary-700: #616161;
$color-primary-600: #757575;
$color-primary-500: #9e9e9e;
$color-primary-400: #bdbdbd;
$color-primary-300: #e0e0e0;
$color-primary-200: #eee;
$color-primary-100: #f5f5f5;
$color-primary-50: #fafafa;
$color-light-green: #087443;
$color-grey-transparent: rgba(0, 0, 0, 0.5);
$color-grey-transparent-10: rgba(0, 0, 0, 0.1);
$color-grey-transparent-75: rgba(0, 0, 0, 0.75);
$color-white-transparent: rgba(255, 255, 255, 0.8);
$color-shimmer-grey: #edeef1;
$color-shimmer-grey-light: #f6f7f8;
$color-grey-text: #5c5a5a !default;

// Colors (http://sachagreif.com/sass-color-variables/)
$black: #000 !default;
$white: #fff !default;
$red: #f00 !default;
$gray: #a39e9d !default;
$light-gray: #cdcbc9 !default;
$color-black: $black !default;
$color-white: $white !default;
$color-gray: $color-primary-600 !default;
$color-grey: $color-gray !default;
$color-light-gray: $color-primary-100 !default;
$color-light-grey: $color-light-gray !default;
$color-dark-gray: $color-primary-900 !default;
$color-dark-grey: $color-dark-gray !default;
$color-lighter-gray: $color-primary-400 !default;
$color-lighter-grey: $color-lighter-gray !default;
$color-off-black: #120e02 !default;
$color-warm-grey: #777 !default;
$color-gray-border: #646464 !default;
$color-lighter-gray-border: #d4d4d4 !default;
